import React, { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import SecureLayout from "../sharedModules/secureLayout";

const Home = React.lazy(() => import("../Container/home"));
const Pricing = React.lazy(() => import("../Container/pricing"));
const FindContainer = React.lazy(() => import("../Container/findContainer"));
const ProfileSetup = React.lazy(() => import("../Container/profileSetup"));
const Dashboard = React.lazy(() => import("../Container/dashboard"));
const Request = React.lazy(() => import("../Container/request"));
const PendingCustomers = React.lazy(() =>
  import("../Container/SystemConfigurations/PendingCustomers")
);
const TradingPriceTrends = React.lazy(() =>
  import("../Container/TradingPriceTrends")
);
const MarketRates = React.lazy(() => import("../Container/MarketRates"));
const Packages = React.lazy(() =>
  import("../Container/SystemConfigurations/Packages")
);
const StripeSuccess = React.lazy(() => import("../Container/StripeSuccess"));
const ActiveCustomers = React.lazy(() =>
  import("../Container/SystemConfigurations/ActiveCustomers")
);
const Invoices = React.lazy(() =>
  import("../Container/SystemConfigurations/Invoices")
);
const EditProfile = React.lazy(() =>
  import("../Container/companyProfile/EditProfile")
);
const HoldCustomers = React.lazy(() =>
  import("../Container/SystemConfigurations/HoldCustomers")
);
const Disputes = React.lazy(() =>
  import("../Container/SystemConfigurations/DisputeList")
);
const ContainerCondition = React.lazy(() =>
  import("../Container/SystemConfigurations/ContainerCondition")
);
const ContainerType = React.lazy(() =>
  import("../Container/SystemConfigurations/ContainerType")
);
const CountriesList = React.lazy(() =>
  import("../Container/SystemConfigurations/Countries")
);
const Badges = React.lazy(() =>
  import("../Container/SystemConfigurations/Badges")
);
const Logs = React.lazy(() => import("../Container/SystemConfigurations/Logs"));
const Cities = React.lazy(() =>
  import("../Container/SystemConfigurations/Cities")
);
const Ports = React.lazy(() =>
  import("../Container/SystemConfigurations/Ports")
);
const TradingRequirementForm = React.lazy(() =>
  import("../Container/tradingRequirement")
);
const TradingDetails = React.lazy(() => import("../Container/tradingDetails"));
const TradingCompanyProfileEdit = React.lazy(() =>
  import("../Container/companyProfile/edit/tradingCompanyProfile")
);
const CustomerDetails = React.lazy(() =>
  import("../Container/SystemConfigurations/CustomerDetails")
);
const InvoiceDetails = React.lazy(() =>
  import("../Container/SystemConfigurations/Invoices/invoiceDetails")
);
const Selling = React.lazy(() => import("../Container/selling"));
const Requirements = React.lazy(() => import("../Container/requirements"));
const RequirementForm = React.lazy(() =>
  import("../Container/requirementForm")
);
const GeneratePdf = React.lazy(() => import("../Container/MobileGeneratePdf"));
const MobileInvoicePdf = React.lazy(() =>
  import("../Container/MobileInvoicePdf")
);
const SwappingRequirementForm = React.lazy(() =>
  import("../Container/swappingRequirement")
);
const SwappingOfferForm = React.lazy(() =>
  import("../Container/swappingRequirement/SwappingOffer")
);
const SwappingDetails = React.lazy(() =>
  import("../Container/swappingDetails")
);
const MyAccount = React.lazy(() => import("../Container/myAccount"));
const CompanyProfile = React.lazy(() => import("../Container/companyProfile"));
const CompanyProfileEdit = React.lazy(() =>
  import("../Container/companyProfile/edit")
);
const CompanyProfileById = React.lazy(() =>
  import("../Container/companyProfile/edit/companyProfile")
);
const ManageUser = React.lazy(() => import("../Container/manageUser"));
const Trade = React.lazy(() => import("../Container/trade"));
const Track = React.lazy(() => import("../Container/track"));
const TrackDetail = React.lazy(() => import("../Container/track/trackDetail"));
const Notification = React.lazy(() => import("../Container/notification"));
const Chat = React.lazy(() => import("../Container/chat"));
const NewPassword = React.lazy(() => import("../Container/newPassword"));
const ChangePassword = React.lazy(() => import("../Container/changePassword"));
const DashboardHome = React.lazy(() => import("../Container/dashboard-home"));
const SellersDashboard = React.lazy(() =>
  import("../Container/sellerFLow/seller-dasboard")
);
const SellersContainerListing = React.lazy(() =>
  import("../Container/sellerFLow/container-listing")
);
const OrderManagement = React.lazy(() =>
  import("../Container/sellerFLow/order-management")
);
const SellerOrderDetails = React.lazy(() =>
  import("../Container/sellerFLow/order-details")
);
const BuyersDashboard = React.lazy(() =>
  import("../Container/buyersFlow/buyer-dasboard")
);
const BuyerOrderManagement = React.lazy(() =>
  import("../Container/buyersFlow/order-management")
);
const BuyerOrderDetails = React.lazy(() =>
  import("../Container/buyersFlow/order-details")
);
const ListingForm = React.lazy(() =>
  import("../Container/sellerFLow/seller-form")
);
const AdminDashboard = React.lazy(() =>
  import("../Container/admin/adminDashboard")
);
const AdminOrderDetails = React.lazy(() =>
  import("../Container/admin/order-details")
);
const AdminOrderManagement = React.lazy(() =>
  import("../Container/admin/order-management")
);
const BulkListingForm = React.lazy(() =>
  import("../Container/sellerFLow/seller-bulk-form")
);
const InvoicePercent = React.lazy(() =>
  import("../Container/SystemConfigurations/InvoicePercent")
);
const AdminDashboardCustom = React.lazy(() =>
  import("../Container/admin/adminDashboardCustom")
);

const MyRoutes = () => {
  useEffect(() => {
    //  console.log('knlk', Home)
  }, []);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Home />
          </React.Suspense>
        }
      />
      <Route
        path="/GeneratePdf/:Id/:Type"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <GeneratePdf />
          </React.Suspense>
        }
      />
      <Route
        path="/InvoicePdf/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <MobileInvoicePdf />
          </React.Suspense>
        }
      />
      <Route
        path="/pricing"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Pricing />
          </React.Suspense>
        }
      />
      <Route
        path="/find-container"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <FindContainer />
          </React.Suspense>
        }
      />
      <Route
        path="/profile-setup"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <ProfileSetup />
          </React.Suspense>
        }
      />
      <Route
        path="/dashboard"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Dashboard />
          </React.Suspense>
        }
      />
      <Route
        path="/requirements"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Requirements />
          </React.Suspense>
        }
      />
      <Route
        path="/request"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Request />
          </React.Suspense>
        }
      />
      <Route
        path="/request/sent"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Selling />
          </React.Suspense>
        }
      />
      <Route
        path="/requirementForm"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <RequirementForm />
          </React.Suspense>
        }
      />
      <Route
        path="/requirementForm/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <RequirementForm />
          </React.Suspense>
        }
      />
      <Route
        path="/view-requirementForm/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <RequirementForm view={true} />
          </React.Suspense>
        }
      />
      <Route
        path="/tradingRequirementForm"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <TradingRequirementForm />
          </React.Suspense>
        }
      />
      <Route
        path="/tradingRequirementForm/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <TradingRequirementForm />
          </React.Suspense>
        }
      />
      <Route
        path="/view-tradingRequirementForm/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <TradingRequirementForm view={true} />
          </React.Suspense>
        }
      />
      <Route
        path="/swappingRequirementForm"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <SwappingRequirementForm />
          </React.Suspense>
        }
      />
      <Route
        path="/swappingRequirementForm/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <SwappingRequirementForm />
          </React.Suspense>
        }
      />
      <Route
        path="/view-swappingRequirementForm/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <SwappingRequirementForm view={true} />
          </React.Suspense>
        }
      />
      <Route
        path="/swappingOfferForm"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <SwappingOfferForm />
          </React.Suspense>
        }
      />
      <Route
        path="/swappingOfferForm/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <SwappingOfferForm />
          </React.Suspense>
        }
      />
      <Route
        path="/view-swappingOfferForm/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <SwappingOfferForm view={true} />
          </React.Suspense>
        }
      />
      <Route
        path="/leasing/received/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Trade />
          </React.Suspense>
        }
      />
      <Route
        path="/trading/received/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <TradingDetails />
          </React.Suspense>
        }
      />
      <Route
        path="/swapping/received/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <SwappingDetails />
          </React.Suspense>
        }
      />
      <Route
        path="/leasing/sent/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Trade />
          </React.Suspense>
        }
      />
      <Route
        path="/trading/sent/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <TradingDetails />
          </React.Suspense>
        }
      />
      <Route
        path="/swapping/sent/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <SwappingDetails />
          </React.Suspense>
        }
      />
      <Route
        path="/leasing/track/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Trade />
          </React.Suspense>
        }
      />
      <Route
        path="/trading/track/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <TradingDetails />
          </React.Suspense>
        }
      />
      <Route
        path="/swapping/track/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <SwappingDetails />
          </React.Suspense>
        }
      />
      <Route
        path="/system-configuration/customerDetails/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <CustomerDetails activeCustomer={true} />
          </React.Suspense>
        }
      />
      <Route
        path="/system-configuration/trading-price-trends"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <TradingPriceTrends />
          </React.Suspense>
        }
      />
      <Route
        path="/market-rates"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <MarketRates />
          </React.Suspense>
        }
      />
      <Route
        path="/invoiceDetails/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <InvoiceDetails />
          </React.Suspense>
        }
      />
      <Route
        path="/system-configuration/activeCustomerDetails/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <CustomerDetails activeCustomer={true} />
          </React.Suspense>
        }
      />
      <Route
        path="/system-configuration/holdCustomerDetails/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <CustomerDetails activeCustomer={true} />
          </React.Suspense>
        }
      />
      <Route
        path="/system-configuration/pendingCustomers"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <PendingCustomers />
          </React.Suspense>
        }
      />
      <Route
        path="/system-configuration/invoicePercent"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <InvoicePercent />
          </React.Suspense>
        }
      />
      <Route
        path="/system-configuration/packages"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Packages />
          </React.Suspense>
        }
      />
      <Route
        path="/stripeSuccess/Confirm/:session_id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <StripeSuccess />
          </React.Suspense>
        }
      />
      <Route
        path="/system-configuration/activeCustomers"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <ActiveCustomers />
          </React.Suspense>
        }
      />
      <Route
        path="/invoices"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Invoices />
          </React.Suspense>
        }
      />
      <Route
        path="/system-configuration/edit-profile/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <EditProfile />
          </React.Suspense>
        }
      />
      <Route
        path="/system-configuration/holdCustomers"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <HoldCustomers />
          </React.Suspense>
        }
      />
      <Route
        path="/system-configuration/disputes"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Disputes />
          </React.Suspense>
        }
      />
      <Route
        path="/system-configuration/countries"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <CountriesList />
          </React.Suspense>
        }
      />
      <Route
        path="/system-configuration/badges"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Badges />
          </React.Suspense>
        }
      />
      <Route
        path="/system-configuration/logs"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Logs />
          </React.Suspense>
        }
      />
      <Route
        path="/system-configuration/cities"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Cities />
          </React.Suspense>
        }
      />
      <Route
        path="/system-configuration/ports"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Ports />
          </React.Suspense>
        }
      />
      <Route
        path="/system-configuration/containerConditions"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <ContainerCondition />
          </React.Suspense>
        }
      />
      <Route
        path="/system-configuration/containerTypes"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <ContainerType />
          </React.Suspense>
        }
      />
      <Route
        path="/tracking"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Track />
          </React.Suspense>
        }
      />
      <Route
        path="/tracking/track-detail"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <TrackDetail />
          </React.Suspense>
        }
      />
      <Route
        path="/my-account"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <CompanyProfile />
          </React.Suspense>
        }
      />
      <Route
        path="/company-profile"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <CompanyProfile />
          </React.Suspense>
        }
      />
      <Route
        path="/company-profile/edit/sent/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <CompanyProfileEdit />
          </React.Suspense>
        }
      />
      <Route
        path="/company-profile/edit/received/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <CompanyProfileEdit />
          </React.Suspense>
        }
      />
      <Route
        path="/company-profile/trading/sent/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <TradingCompanyProfileEdit />
          </React.Suspense>
        }
      />
      <Route
        path="/company-profile/trading/received/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <TradingCompanyProfileEdit />
          </React.Suspense>
        }
      />
      <Route
        path="/company-profile/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <CompanyProfileById />
          </React.Suspense>
        }
      />
      <Route
        path="/manage-user"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <ManageUser />
          </React.Suspense>
        }
      />
      <Route
        path="/notification"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Notification />
          </React.Suspense>
        }
      />
      <Route
        path="/chat"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Chat />
          </React.Suspense>
        }
      />
      <Route
        path="/new-password"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <NewPassword />
          </React.Suspense>
        }
      />
      <Route
        path="/change-password"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <ChangePassword />
          </React.Suspense>
        }
      />
      <Route
        path="/dashboard-home"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <DashboardHome />
          </React.Suspense>
        }
      />
      <Route
        path="/buyer-dashboard"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <BuyersDashboard />
          </React.Suspense>
        }
      />
      <Route
        path="/buyer-order-management"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <BuyerOrderManagement />
          </React.Suspense>
        }
      />
      <Route
        path="/buyer-order-details"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <BuyerOrderDetails />
          </React.Suspense>
        }
      />
      <Route
        path="/buyer-order-details/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <BuyerOrderDetails />
          </React.Suspense>
        }
      />
      <Route
        path="/seller-dashboard"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <SellersDashboard />
          </React.Suspense>
        }
      />
      <Route
        path="/seller-container-listing"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <SellersContainerListing />
          </React.Suspense>
        }
      />
      <Route
        path="/seller-order-management"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <OrderManagement />
          </React.Suspense>
        }
      />
      <Route
        path="/seller-order-details"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <SellerOrderDetails />
          </React.Suspense>
        }
      />
      <Route
        path="/seller-order-details/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <SellerOrderDetails />
          </React.Suspense>
        }
      />
      <Route
        path="/listing-form"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <ListingForm />
          </React.Suspense>
        }
      />
      <Route
        path="/listing-form/edit/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <ListingForm />
          </React.Suspense>
        }
      />
      <Route
        path="/listing-form/view/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <ListingForm />
          </React.Suspense>
        }
      />
      <Route
        path="/listing-form-bulk"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <BulkListingForm />
          </React.Suspense>
        }
      />
      <Route
        path="/admin-dashboard"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <AdminDashboard />
          </React.Suspense>
        }
      />
      <Route
        path="/admin-dashboard-custom"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <AdminDashboardCustom />
          </React.Suspense>
        }
      />
      <Route
        path="/admin-order-details"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <AdminOrderDetails />
          </React.Suspense>
        }
      />
      <Route
        path="/admin-order-details/:Id"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <AdminOrderDetails />
          </React.Suspense>
        }
      />
      <Route
        path="/admin-order-management"
        element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <AdminOrderManagement />
          </React.Suspense>
        }
      />
    </Routes>
  );
};

export default MyRoutes;
